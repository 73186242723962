<script setup>
import {defineProps, inject, onMounted, watch} from "vue";
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import ContractService from "@/services/ContractService";
import fileService from "@/services/FileService";

const props = defineProps({
  showDashboardTab: Boolean,
  showUploadTab: Boolean,
});

const redirectUserToFirstAvailableTab = inject("redirectUserToFirstAvailableTab");


const router = useRouter();
const store = useStore();

const consent = ref(false);
const selectedFile = ref();
const selectedTerm = ref();
const terms = ref([]);
const errorMessages = ref({
  contract: '',
  file: '',
});

watch(() => selectedTerm.value, (newVal) => {
  if (newVal) {
    errorMessages.value.contract = '';
  }
});

watch(() => selectedFile.value, (newVal) => {
  if (newVal) {
    errorMessages.value.file = '';
  }
});

const userIsAdmin = ref(false);
const isPageLoading = ref(true);

onMounted(async () => {
  console.log("PageLoading 1", isPageLoading.value);
  isPageLoading.value = true;
  if (!props.showUploadTab) {
    redirectUserToFirstAvailableTab();
    return;
  }

  if (props.showDashboardTab) {
    userIsAdmin.value = true;
  }

  await getContractTerms();
  if (terms.value.length > 0) {
    selectedTerm.value = terms.value[0];
  }

  isPageLoading.value = false;
  console.log("PageLoading 2", isPageLoading.value);
});

async function getContractTerms() {
  const response = await ContractService.listContracts(router, store);
  if (response.status === "success") {
    terms.value = response.contracts.map(contract => ({
      ...contract,
      displayLabel: `${contract.term_start_date_formatted} - ${contract.term_end_date_formatted}`
    }));
  } else {
    toast.add({ severity: "error", summary: "Error", detail: response.message });
  }
}

const onSelect = (event) => {
  console.log(event);
  selectedFile.value = event.files[0];
};

const fileUpload = ref(null);
const loading = ref(false);
async function uploadFile() {
  loading.value = true;
  if(!selectedTerm.value) {
    errorMessages.value.contract = 'Please select a contract term';
  }

  if (!selectedFile.value) {
    errorMessages.value.file = 'Please select a file to upload';
  }

  if (errorMessages.value.contract || errorMessages.value.file) {
    loading.value = false;
    return;
  }


  const response = await fileService.uploadFile(router, store, selectedFile.value, selectedTerm.value.id);
  if (response.status === "success") {
    toast.add({ severity: "success", summary: "File Upload Successful!", detail: "Your file will be processed and you will be notified via email upon completion. If you don't receive an email, contact your GME Account Manager." });
  } else {
    toast.add({ severity: "error", summary: "File Upload Failed!", detail: "Error encountered. Please try again.", life: 5000 });
  }

  loading.value = false;

  // Clear the file input
  selectedFile.value = null;
  selectedTerm.value = null;
  fileUpload.value.clear();
  consent.value = false;

}

const toast = useToast();
</script>

<template>
  <h1 class="text-3xl font-bold py-8 text-primaryBlue">Student CSV File Upload</h1>
<div v-if="props.showUploadTab">
  <Card >
    <template #content>
      <div class="grid grid-cols-12 gap-8">
        <!-- Left Section -->
        <div id="left" class="col-span-5 px-8 py-12 text-wrap">
          <h2 class="text-xl font-bold mb-4">File Upload Guidance:</h2>
          <ul class="list-decimal ml-6 space-y-4">
            <li>Make sure your file format is CSV, not Excel or any other type.</li>
            <li>
              Column headers should be the exact same spelling and order as follows:
              Student Identifier, School Name, Reporting Stream Name, Cancel (optional)
              <ul class="list-disc ml-6 mt-2">
                <li>To cancel a student's registration, put "yes" in the Cancel column for that student.</li>
              </ul>
            </li>
            <li>Ensure there is only one row per student, per school.</li>
            <li>
              Duplicate records will be stored only once. If student information is changed in subsequent uploads, only the last uploaded data for that student will be saved.
            </li>
          </ul>
        </div>

        <div class="flex justify-center items-center col-span-1">
          <Divider layout="vertical" type="solid" class="h-full w-[10px]" />
        </div>

        <div id="right" class="col-span-5 px-8 py-12 text-wrap">
          <div v-if="isPageLoading" id="term-selector-skeleton" class="w-full animate-pulse">
            <p class="pb-2 bg-gray-300 h-6 w-1/3 mb-2"></p>
            <div class="h-10 bg-gray-200 rounded mb-4"></div>
            <div class="h-6 bg-gray-200 rounded"></div>
          </div>

          <div v-else id="term-selector" class="w-full">
            <p class="pb-2">Contract Term:</p>
            <Select v-if="terms.length>0" v-model="selectedTerm" :options="terms" optionLabel="displayLabel" placeholder="Select a Term" class="w-full" />
            <Message severity="error" v-if="terms.length===0 && userIsAdmin"> No Contracts Available </Message>
            <Message severity="error" v-if="terms.length===0 && !userIsAdmin"> Contract period not set. Please contact your GME Account Manager. </Message>
            <Message severity="error" v-if="errorMessages.contract"> {{errorMessages.contract}} </Message>
          </div>

          <p class="pt-8 pb-2">Select File:</p>
          <div id="file-selector" class="card border-2 rounded-lg bg-transparent border-grey py-2 px-2 ">
            <Toast />
            <FileUpload
                ref="fileUpload"
                mode="basic"
                name="fileToUpload"
                accept=".csv"
                invalidFileTypeMessage="Invalid file type. Please upload a CSV file."
                :maxFileSize="5000000"
                @select="onSelect"
                :customUpload="true"
                chooseLabel="Choose File"
                class="justify-self-start bg-green-500 border-none h-[40px]"
            />
            <Message severity="error" v-if="errorMessages.file"> {{errorMessages.file}} </Message>
          </div>

          <div id="consent" class="flex items-start py-8">
            <div class="card flex">
              <Checkbox v-model="consent" :binary="true" />
            </div>
            <div class="flex-1 ml-4">
              <label for="consent" class="">
                I hereby declare, on behalf of my organization, that we have
                obtained the consent of our registrants to provide their personal information to guard.me International
                Insurance, for the purposes of enrolling them in the Digital Doctor program.
              </label>
            </div>
          </div>

          <Button label="Upload CSV" @click="uploadFile" :disabled="!consent || !selectedFile || !selectedTerm" :loading="loading" severity="secondary" class="bg-primaryBlue text-white" />
        </div>
      </div>
    </template>
  </Card>



</div>
</template>

<style scoped>
.p-fileupload {
  justify-content: start;
}
</style>