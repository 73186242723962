<script setup>
import {inject, defineProps, onMounted, ref} from "vue";
import schoolService from "@/services/SchoolService";
import accountService from "@/services/AccountService";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const store = useStore();
const router = useRouter();

// Inject the handleNotice function provided by the parent
const showToastMessage = inject("showToastMessage");
const redirectUserToFirstAvailableTab = inject("redirectUserToFirstAvailableTab");

if (!showToastMessage) {
  console.error("showToastMessage is not provided");
}

const props = defineProps({
  showDashboardTab: Boolean,
  showSchoolsTab: Boolean,
  showApiTab: Boolean,
  showUsersTab: Boolean,
  showDocsTab: Boolean,
  showUploadTab: Boolean,
});

const schoolCount = ref(0);
onMounted(async () => {
  if (!props.showDashboardTab) {
    redirectUserToFirstAvailableTab();
    return;
  }


  const response = await schoolService.countSchools(router, store);
  if (response.status === 'success') {
    schoolCount.value = response.count;
    console.log('Number of schools:', response.count);
  } else {
    console.error('Error fetching school count:', response.message);
  }
});

const accountCount = ref(0);
onMounted(async () => {
  const response = await accountService.countAccounts(router, store);
  if (response.status === 'success') {
    accountCount.value = response.count;
    console.log('Number of accounts:', response.count);
  } else {
    console.error('Error fetching account count:', response.message);
  }
});

</script>

<template>
  <div v-if="props.showDashboardTab">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 py-4 text-red-600">
      <Card v-if="showSchoolsTab" id="schools" class="text-primaryBlue cursor-pointer" @click="$router.push('/schools')" >
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl  h-[66px]">
            <div>
              <h2>Schools</h2>
              <p class="text-4xl font-bold">{{schoolCount}}</p>
            </div>
            <i class="pi pi-building justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>
      <Card v-if="showUsersTab" id="users" class="text-primaryBlue cursor-pointer" @click="$router.push('/users')">
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl h-[66px]">
            <div>
              <h2>Users</h2>
              <p class="text-4xl font-bold">{{accountCount}}</p>
            </div>
            <i class="pi pi-fw pi-users justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>
      <Card v-if="showUploadTab" id="upload" class="text-primaryBlue cursor-pointer" @click="$router.push('/upload')">
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl h-[66px]">
            <div class="flex flex-col justify-center h-full">
              <h2>Upload</h2>
            </div>
            <i class="pi pi-upload justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>
      <Card v-if="showApiTab" id="eligibility-check" class="text-primaryBlue cursor-pointer" @click="$router.push('/eligibility-check')">
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl h-[66px]">
            <div class="flex flex-col justify-center h-full">
              <h2>Eligibility Check</h2>
            </div>
            <i class="pi pi-verified justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>
      <Card v-if="showDocsTab" id="docs" class="text-primaryBlue cursor-pointer" @click="$router.push('/docs')">
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl h-[66px]">
            <div class="flex flex-col justify-center h-full">
              <h2>Documentation</h2>
            </div>
            <i class="pi pi-file justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>

    </div>
  </div>
</template>

<style scoped>

</style>