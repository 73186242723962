<script setup>

import {defineProps, inject, onMounted, ref, watch} from "vue";
import SchoolService from "@/services/SchoolService";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

//general
const store = useStore();
const router = useRouter();
const props = defineProps({
  showSchoolsTab: Boolean,
});

// eslint-disable-next-line no-unused-vars
const showToastMessage = inject("showToastMessage");

onMounted(() => {
  listSchoolDetail();
});

const listSchoolDetail = () => {
  SchoolService.listSchoolDetails(router, store).then((response) => {
    schools.value = response.schools.map(school => ({
      ...school,
      id: school.id,
      name: school.name,
      firstAlias: school.aliases?.[0]?.alias || 'N/A',
      aliasCount: school.aliases?.length || 0,
      firstReportingStream: school.reporting_streams?.[0]?.name || 'N/A',
      reportingStreamCount: school.reporting_streams?.length || 0,
      aliasList: school.aliases?.map(alias => alias.alias).join(', ') || 'N/A',
      reportingStreamList: school.reporting_streams?.map(stream => stream.name).join(', ') || 'N/A',
      contractCount: school.contracts?.length || 0,
      contractNumber: school.contracts?.[0]?.contract_number || 'N/A',
      contractTermStartDate: school.contracts?.[0]?.term_start_date || '',
      contractTermEndDate: school.contracts?.[0]?.term_end_date || '',
    }));
    table_loading.value = false;
  }).catch((error) => {
    console.error(error);
  });
};

// general data table
const schools = ref([]);
const filters = ref({
  global: {value: null},
  name: {value: null},
  alias: {value: null},
  contract_name: {value: null}
});

const selectedSchool = ref(null);



const table_loading = ref(true);

// drawer
const newSchoolDrawer = ref(false);
const detailSchoolDrawer = ref(false);

watch(detailSchoolDrawer, (newValue) => {
  if (!newValue) {
    contractMode.value = 'display';
    showNewAliasInput.value = false;
    showNewReportingStreamsInput.value = false;
    newAlias.value = '';
    newReportingStream.value = '';
  }
});

const toggleNewSchoolDrawer = () => {
  newSchoolDrawer.value = !newSchoolDrawer.value;
}

const selectSchool = (school) => {
  detailSchoolDrawer.value = true;
  selectedSchool.value = school;
}



const aliasesCollapsed = ref(false);
const aliasesIcon = ref('pi pi-chevron-down');
const aliasesEditingRows = ref([]);
const aliasEditLoading = ref(false);

const reportingStreamsCollapsed = ref(false);
const reportingStreamsIcon = ref('pi pi-chevron-down');
const reportingStreamsEditingRows = ref([]);
const reportingStreamEditLoading = ref(false);

const contractsCollapsed = ref(false);
const contractsIcon = ref('pi pi-chevron-down');
const contractMode = ref('display');

const selectedContract = ref(null);
const contractModeChange = (mode) => {
  contractMode.value = mode;
  if(mode === 'display') {
    selectedContract.value = null;
  } else {
    selectedContract.value = selectedSchool.value.contracts[0];
  }
};

const addContract = () => {
  contractMode.value = 'edit';
  selectedContract.value = {
    id: -1,
    contract_number: '',
    term_start_date: '',
    term_end_date: '',
  };
};

const saveContractLoading = ref(false);

const saveContract = async () => {
  saveContractLoading.value = true;
  if (selectedContract.value.id > 0) {
    const updateContractResponse = await SchoolService.updateContract(router, store, selectedSchool.value.id, selectedContract.value.id, selectedContract.value.term_start_date, selectedContract.value.term_end_date);
    if (updateContractResponse.status === 'success') {
      console.log(updateContractResponse);
      showToastMessage('success', 'Enabled', `Contract ${selectedContract.value.contract_number} saved for school ${selectedSchool.value.name}`);
      const updatedContract = updateContractResponse.contract;
      selectedSchool.value.contracts[0].term_start_date = new Date(updatedContract.term_start_date).toISOString().split('T')[0];
      selectedSchool.value.contracts[0].term_end_date = new Date(updatedContract.term_end_date).toISOString().split('T')[0];
      updateSchoolsTableWithoutReloading(updatedContract, 'contract');
    } else {
      showToastMessage('error', 'Error', updateContractResponse.message);
    }
  } else {
    const createContractResponse = await SchoolService.createContract(router, store, selectedSchool.value.id, selectedContract.value.contract_number, selectedContract.value.term_start_date, selectedContract.value.term_end_date);
    if (createContractResponse.status === 'success') {
      console.log(createContractResponse);
      showToastMessage('success', 'Enabled', `Contract ${selectedContract.value.contract_number} saved for school ${selectedSchool.value.name}`);
      const createdContract = createContractResponse.contract;
      selectedSchool.value.contracts.push({
        id: createdContract.id,
        contract_number: createdContract.contract_number,
        term_start_date: createdContract.term_start_date,
        term_end_date: createdContract.term_end_date,
      });
      selectedSchool.value.contractCount = 1;

      updateSchoolsTableWithoutReloading(selectedSchool.value, 'contract');
    } else {
      showToastMessage('error', 'Error', createContractResponse.message);
    }
  }



  saveContractLoading.value = true;
  contractMode.value = 'display';
  selectedSchool.value.contracts[0] = selectedContract.value;
};



const toggleAliasesPanel = () => {
  aliasesCollapsed.value = !aliasesCollapsed.value;
  aliasesIcon.value = aliasesCollapsed.value ? 'pi pi-chevron-right' : 'pi pi-chevron-down';
};

const toggleReportingStreamsPanel = () => {
  reportingStreamsCollapsed.value = !reportingStreamsCollapsed.value;
  reportingStreamsIcon.value = reportingStreamsCollapsed.value ? 'pi pi-chevron-right' : 'pi pi-chevron-down';
};

const toggleContractsPanel = () => {
  contractsCollapsed.value = !contractsCollapsed.value;
  contractsIcon.value = contractsCollapsed.value ? 'pi pi-chevron-right' : 'pi pi-chevron-down';
};

const updateSchoolsTableWithoutReloading = (school, field) => {
  const relatedSchool = schools.value.find(school => school.id === selectedSchool.value.id);

  if(field === 'alias'){
    relatedSchool.firstAlias = selectedSchool.value.aliases[0].alias;
    relatedSchool.aliasCount = selectedSchool.value.aliases.length;
  } else if(field === 'reporting_stream'){
    relatedSchool.firstReportingStream = selectedSchool.value.reporting_streams[0].name;
    relatedSchool.reportingStreamCount = selectedSchool.value.reporting_streams.length;
  } else if(field === 'contract'){
    relatedSchool.contractNumber = selectedSchool.value.contracts[0].contract_number;
    relatedSchool.contractTermStartDate = selectedSchool.value.contracts[0].term_start_date;
    relatedSchool.contractTermEndDate = selectedSchool.value.contracts[0].term_end_date;
  }
};

const onRowEditSaveAlias = async (event) => {
  aliasEditLoading.value = true;
  let { newData, index } = event;

  const editAliasResponse = await SchoolService.editAlias(router, store, newData.id, newData.alias);
  if (editAliasResponse.status === 'success') {
    showToastMessage('success', 'Enabled', `Alias ${newData.alias} saved for school ${selectedSchool.value.name}`);
    selectedSchool.value.aliases[index].alias = newData.alias;

    console.log(aliasesEditingRows.value);
    updateSchoolsTableWithoutReloading(selectedSchool.value, 'alias');
  } else {
    showToastMessage('error', 'Error', 'Failed to save alias');
  }

  aliasesEditingRows.value = [];
  aliasEditLoading.value = false;
};

const onRowEditSaveReportingStream = async (event) => {
  reportingStreamEditLoading.value = true;

  let { newData, index } = event;

  const editReportingStreamResponse = await SchoolService.editReportingStream(router, store, newData.id, newData.name);
  if (editReportingStreamResponse.status === 'success') {
    showToastMessage('success', 'Enabled', `Reporting Stream ${newData.name} saved for school ${selectedSchool.value.name}`);
    selectedSchool.value.reporting_streams[index].name = newData.name;

    updateSchoolsTableWithoutReloading(selectedSchool.value, 'reporting_stream');
  } else {
    showToastMessage('error', 'Error', 'Failed to save reporting stream');
  }

  reportingStreamsEditingRows.value = [];
  reportingStreamEditLoading.value = false;
};

const showNewAliasInput = ref(false);
const saveAliasLoading = ref(false);
const newAlias = ref('');

const showNewReportingStreamsInput = ref(false);
const saveReportingStreamLoading = ref(false);
const newReportingStream = ref('');

const addReportingStream = () => {
  showNewReportingStreamsInput.value = true;
};

const addAlias = () => {
  showNewAliasInput.value = true;
};

const saveAlias = async() => {
  saveAliasLoading.value = true;

  const createAliasResponse = await SchoolService.createAlias(router, store, selectedSchool.value.id, newAlias.value);
  if (createAliasResponse.status === 'success') {
    showToastMessage('success', 'Enabled', `${newAlias.value} added as alias for school ${selectedSchool.value.name}`);
    const createdAlias = createAliasResponse.alias;
    console.log(createdAlias);
    selectedSchool.value.aliases.push({
      id: createdAlias.id,
      alias: newAlias.value,
    });
    updateSchoolsTableWithoutReloading(selectedSchool.value, 'alias');
  } else {
    showToastMessage('error', 'Error', 'Failed to add alias');
  }

  saveAliasLoading.value = false;
  newAlias.value = '';
  showNewAliasInput.value = false;
};

const saveReportingStream = async () => {
  saveReportingStreamLoading.value = true;

  const createReportingStreamResponse = await SchoolService.createReportingStream(router, store, selectedSchool.value.id, newReportingStream.value);
  if (createReportingStreamResponse.status === 'success') {
    showToastMessage('success', 'Enabled', `${newReportingStream.value} added as reporting stream for school ${selectedSchool.value.name}`);
    const createdReportingStream = createReportingStreamResponse.reporting_stream;
    console.log(createdReportingStream);
    selectedSchool.value.reporting_streams.push({
      id: createdReportingStream.id,
      name: createdReportingStream.name,
    });

    updateSchoolsTableWithoutReloading(selectedSchool.value, 'reporting_stream');
  } else {
    showToastMessage('error', 'Error', 'Failed to add reporting stream');
  }

  newReportingStream.value = '';
  showNewReportingStreamsInput.value = false;
};

const cancelNewAlias = () => {
  showNewAliasInput.value = false;
  newAlias.value = '';
};

const cancelNewReportingStream = () => {
  showNewReportingStreamsInput.value = false;
  newReportingStream.value = '';
};

const newSchool = ref({
  name: '',
  aliases: [],
  reporting_streams: [],
  contracts: null,
});

const newSchoolLoading = ref(false);

const addNewTempAlias = () => {
  console.log('add new alias');
  newSchool.value.aliases.push('');
};


const deleteNewTempAlias = (index) => {
  newSchool.value.aliases.splice(index, 1);
};

const addNewTempReportingStream = () => {
  console.log('add new reporting stream');
  newSchool.value.reporting_streams.push('');
};

const deleteNewTempReportingStream = (index) => {
  newSchool.value.reporting_streams.splice(index, 1);
};

const addNewTempContract = () => {
  console.log('add new contract');
  newSchool.value.contracts = {
    contract_number: '',
    term_start_date: '',
    term_end_date: '',
  };
};

const deleteNewTempContract = () => {
  newSchool.value.contracts = null;
};

const cancelNewSchool = () => {
  newSchoolDrawer.value = false;
  newSchool.value = {
    name: '',
    aliases: [],
    reporting_streams: [],
    contracts: null,
  };
};

const addNewSchool = async () => {
  newSchoolLoading.value = true;
  //addNewSchoolWithAssociatedData
  const newSchoolResponse = await SchoolService.addNewSchoolWithAssociatedData(router, store, newSchool.value.name, newSchool.value.reporting_streams, newSchool.value.aliases, newSchool.value.contracts);
  if (newSchoolResponse.status === 'success') {
    showToastMessage('success', 'Enabled', `School ${newSchool.value.name} added successfully`);
    showNewSchoolAliasPanel.value = false;
    showNewSchoolReportingStreamPanel.value = false;
    showNewSchoolContractsPanel.value = false;
    listSchoolDetail();
    newSchool.value = {
      name: '',
      aliases: [],
      reporting_streams: [],
      contracts: null,
    };
  } else {
    showToastMessage('error', 'Error', newSchoolResponse.message);
  }

  newSchoolDrawer.value = false;
  newSchoolLoading.value = false;
};

const showNewSchoolAliasPanel = ref(false);
const showNewSchoolReportingStreamPanel = ref(false);
const showNewSchoolContractsPanel = ref(false);

const toggleNewSchoolAliasPanel = () => {
  showNewSchoolAliasPanel.value = !showNewSchoolAliasPanel.value;
};

const toggleNewSchoolReportingStreamPanel = () => {
  showNewSchoolReportingStreamPanel.value = !showNewSchoolReportingStreamPanel.value;
};

const toggleNewSchoolContractsPanel = () => {
  showNewSchoolContractsPanel.value = !showNewSchoolContractsPanel.value;
};

watch(newSchoolDrawer, (newValue) => {
  if (!newValue) {
    newSchool.value.aliases = [];
  }
});

</script>

<template>
  <div v-if="props.showSchoolsTab">
    <h1 class="text-3xl font-bold py-8 text-primaryBlue flex items-center">
      Schools Information
      <Button class="py-1 ml-8 flex items-center" @click="toggleNewSchoolDrawer">
        <i class="pi pi-plus mr-2"></i>
        <p>New School</p>
      </Button>
      <Drawer v-model:visible="newSchoolDrawer" position="right" class="w-[500px]">
        <template #header>
          <h1 class="text-2xl justify-end"></h1>
        </template>
        <h1 class="text-2xl mt-8 mb-6 px-4">New School</h1>
        <div class="flex flex-col gap-2 px-4">
          <label for="newSchoolName">School Name</label>
          <InputText id="newSchoolName" v-model="newSchool.name" />
          <div id="new-school-alias">
            <div id="new-school-alias-button-area"
                 class="relative w-full h-full">
              <Button
                  class="bg-transparent border-none pl-0 absolute transition-transform duration-500 ease-in-out"
                  :class="showNewSchoolAliasPanel ? 'translate-x-[19rem] p-button-danger' : 'translate-x-0 '"
                  :icon="showNewSchoolAliasPanel ? 'pi pi-times' : 'pi pi-plus'"
                  iconPos="left"
                  :label="showNewSchoolAliasPanel ? 'Hide Alias Input' : 'Add Alias'"
                  outlined
                  @click="toggleNewSchoolAliasPanel"/>
            </div>
            <Panel v-if="showNewSchoolAliasPanel">
              <template #header>
                <div class="panel-header ">
                  <div>Aliases</div>
                </div>
              </template>
              <div id="new-alias-input-field" class="w-full pl-4 flex flex-col items-center">
                <div v-for="(alias, index) in newSchool.aliases" :key="index" class="w-full flex items-center justify-between my-2">
                  <InputText type="text" v-model="newSchool.aliases[index]" class="w-64"/>

                  <Button
                      icon="pi pi-trash"
                      class="p-button-danger border-none"
                      outlined
                      rounded
                      @click="deleteNewTempAlias(index)"
                  />
                </div>
              </div>
              <Button
                  class="bg-transparent border-none pl-0"
                  icon="pi pi-plus"
                  iconPos="left"
                  label="Add another Alias"
                  outlined
                  @click="addNewTempAlias" />
            </Panel>
          </div>

          <div id="new-school-reporting-stream">
            <div id="new-school-reporting-stream-button-area"
                 class="relative w-full h-full">
              <Button
                  class="bg-transparent border-none pl-0 absolute transition-transform duration-500 ease-in-out"
                  :class="showNewSchoolReportingStreamPanel ? 'translate-x-[13rem] p-button-danger' : 'translate-x-0 '"
                  :icon="showNewSchoolReportingStreamPanel ? 'pi pi-times' : 'pi pi-plus'"
                  iconPos="left"
                  :label="showNewSchoolReportingStreamPanel ? 'Hide Reporting Stream Input' : 'Add Reporting Stream'"
                  outlined
                  @click="toggleNewSchoolReportingStreamPanel"/>
            </div>
            <Panel v-if="showNewSchoolReportingStreamPanel">
              <template #header>
                <div class="panel-header ">
                  <div>Reporting Streams</div>
                </div>
              </template>
              <div id="new-alias-input-field" class="w-full pl-4 flex flex-col items-center">
                <div v-for="(reporting_stream, index) in newSchool.reporting_streams" :key="index" class="w-full flex items-center justify-between my-2">
                  <InputText type="text" v-model="newSchool.reporting_streams[index]" class="w-64"/>

                  <Button
                      icon="pi pi-trash"
                      class="p-button-danger border-none"
                      outlined
                      rounded
                      @click="deleteNewTempReportingStream(index)"
                  />
                </div>
              </div>
              <Button
                  class="bg-transparent border-none pl-0"
                  icon="pi pi-plus"
                  iconPos="left"
                  label="Add another Reporting Stream"
                  outlined
                  @click="addNewTempReportingStream" />
            </Panel>
          </div>

          <div id="new-school-contracts">
            <div id="new-school-contracts-button-area"
                 class="relative w-full h-full">
              <Button
                  class="bg-transparent border-none pl-0 absolute transition-transform duration-500 ease-in-out"
                  :class="showNewSchoolContractsPanel ? 'translate-x-[13rem] p-button-danger' : 'translate-x-0 '"
                  :icon="showNewSchoolContractsPanel ? 'pi pi-times' : 'pi pi-plus'"
                  iconPos="left"
                  :label="showNewSchoolContractsPanel ? 'Hide Contract Input' : 'Add Contract'"
                  outlined
                  @click="toggleNewSchoolContractsPanel"/>
            </div>
            <Panel v-if="showNewSchoolContractsPanel">
              <template #header>
                <div class="panel-header ">
                  <div>Contracts</div>
                </div>
              </template>
              <div v-if="newSchool.contracts" id="new-contract-input-field" class="w-full pl-4 space-y-2">
                <div class="flex items-center justify-between w-full">
                  <InputText v-model="newSchool.contracts.contract_number" placeholder="Contract Number" class="w-64" />
                  <Button
                      icon="pi pi-trash"
                      class="p-button-danger border-none"
                      outlined
                      rounded
                      @click="deleteNewTempContract"
                  />
                </div>

                <div>
                  <DatePicker v-model="newSchool.contracts.term_start_date" class="w-64" />
                </div>

                <div>
                  <DatePicker v-model="newSchool.contracts.term_end_date" class="w-64" />
                </div>
              </div>
              <Button
                  class="bg-transparent border-none pl-0"
                  icon="pi pi-plus"
                  iconPos="left"
                  label="Add Contract"
                  outlined
                  @click="addNewTempContract" />
            </Panel>
            <div id="create-school-button-area" class="my-8 flex justify-between items-center">
              <Button
                  class="font-bold ml-24 px-6"
                  label="Cancel"
                  outlined
                  @click="cancelNewSchool" />

              <Button
                  class="font-bold px-6"
                  icon="pi pi-check"
                  iconPos="left"
                  label="Create School"
                  :loading="newSchoolLoading"
                  @click="addNewSchool" />
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer v-model:visible="detailSchoolDrawer" position="right" class="w-[500px]">
        <template #header>
          <h1 class="text-2xl justify-end"></h1>
        </template>
        <h1 class="text-2xl mt-8 mb-6">{{selectedSchool.name}}</h1>

        <Panel toggleable :collapsed="aliasesCollapsed">
          <template #header>
            <div class="panel-header flex items-center justify-between">
              <div class="expand-button-container" @click="toggleAliasesPanel">
                <Button :icon=aliasesIcon class="p-button-text" />
              </div>

              <div>Aliases</div>
            </div>
          </template>

          <template #toggleicon>
            <Tag severity="danger" :value="selectedSchool.aliasCount" class="bg-red-300 text-red-900"></Tag>
          </template>

          <div>
            <DataTable v-model:editingRows="aliasesEditingRows"
                       :value="selectedSchool.aliases"
                       editMode="row"
                       dataKey="id"
                       @row-edit-save="onRowEditSaveAlias"
                       id="aliasesTable"
            >

              <Column field="alias" class="border-none py-1 w-72">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" fluid/>
                </template>
              </Column>

              <Column :rowEditor="true" bodyStyle="text-align:right" class="border-none pr-0 py-1"></Column>

              <template #footer>
                <div id="new-alias-input-field" class="w-full pl-4" v-if="showNewAliasInput">
                  <InputText type="text" v-model="newAlias" class="w-64"/>
                  <Button
                      icon="pi pi-check"
                      class="p-button-success border-none ml-12"
                      outlined
                      rounded
                      :loading="saveAliasLoading"
                      @click="saveAlias"
                  />
                  <Button
                      icon="pi pi-trash"
                      class="p-button-danger border-none"
                      outlined
                      rounded
                      @click="cancelNewAlias"
                  />
                </div>

                <Button
                    class="bg-transparent border-none pl-0"
                    icon="pi pi-plus"
                    iconPos="left"
                    label="Add another Alias"
                    outlined
                    @click="addAlias" />
              </template>
            </DataTable>
          </div>
        </Panel>

        <Panel toggleable :collapsed="reportingStreamsCollapsed">
          <template #header>
            <div class="panel-header flex items-center justify-between">
              <div class="expand-button-container" @click="toggleReportingStreamsPanel">
                <Button :icon=reportingStreamsIcon class="p-button-text" />
              </div>

              <div>Reporting Streams</div>
            </div>
          </template>

          <template #toggleicon>
            <Tag severity="danger" :value="selectedSchool.reportingStreamCount" class="bg-red-300 text-red-900"></Tag>
          </template>

          <div>
            <DataTable v-model:editingRows="reportingStreamsEditingRows"
                       :value="selectedSchool.reporting_streams"
                       editMode="row"
                       dataKey="id"
                       @row-edit-save="onRowEditSaveReportingStream"
                       id="reportingStreamsTable"
            >

              <Column field="name" class="border-none py-1 w-72">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" fluid/>
                </template>
              </Column>

              <Column :rowEditor="true" bodyStyle="text-align:right" class="border-none pr-0 py-1"></Column>

              <template #footer>
                <div id="new-alias-input-field" class="w-full pl-4" v-if="showNewReportingStreamsInput">
                  <InputText type="text" v-model="newReportingStream" class="w-64"/>
                  <Button
                      icon="pi pi-check"
                      class="p-button-success border-none ml-12"
                      outlined
                      rounded
                      :loading="saveReportingStreamLoading"
                      @click="saveReportingStream"
                  />
                  <Button
                      icon="pi pi-trash"
                      class="p-button-danger border-none"
                      outlined
                      rounded
                      @click="cancelNewReportingStream"
                  />
                </div>

                <Button
                    class="bg-transparent border-none pl-0"
                    icon="pi pi-plus"
                    iconPos="left"
                    label="Add another Reporting Stream"
                    outlined
                    @click="addReportingStream" />
              </template>
            </DataTable>
          </div>
        </Panel>

        <Panel toggleable :collapsed="contractsCollapsed">
          <template #header>
            <div class="panel-header flex items-center justify-between">
              <div class="expand-button-container" @click="toggleContractsPanel">
                <Button :icon=contractsIcon class="p-button-text" />
              </div>

              <div>Contracts</div>
            </div>
          </template>

          <template #toggleicon>
            <Tag severity="danger" :value="selectedSchool.contractCount" class="bg-red-300 text-red-900"></Tag>
          </template>

          <div v-if="selectedSchool.contractCount > 0 || contractMode === 'edit'">
            <div class="flex justify-between items-center">
              <div class="flex-1">
                <div v-if="contractMode === 'display'" class="w-full pl-4">
                  {{ selectedSchool.contractNumber }}
                </div>
                <div v-else-if="contractMode === 'edit'" class="w-full pl-4">
                  <InputText v-model="selectedContract.contract_number" placeholder="Contract Number" class="w-64" :disabled="selectedSchool.contractCount > 0"/>
                </div>
              </div>
              <div id="contract-right" class="flex space-x-2">
                <Button
                    v-if="contractMode === 'display'"
                    icon="pi pi-pencil"
                    class="border-none"
                    outlined
                    rounded
                    @click="contractModeChange('edit')"
                />
                <Button
                    v-else-if="contractMode === 'edit'"
                    icon="pi pi-times"
                    class="border-none"
                    outlined
                    rounded
                    @click="contractModeChange('display')"
                />
              </div>
            </div>

            <div v-if="contractMode === 'display'" class="w-full pl-4 mt-2">
              {{ selectedSchool.contractTermStartDate }}
            </div>
            <div v-else-if="contractMode === 'edit'" class="w-full pl-4 mt-2">
              <DatePicker v-model="selectedContract.term_start_date" class="w-64"/>
            </div>

            <div v-if="contractMode === 'display'" class="w-full mt-2 pl-4">
              {{ selectedSchool.contractTermEndDate }}
            </div>
            <div v-else-if="contractMode === 'edit'" class="w-full mt-2 pl-4">
              <DatePicker v-model="selectedContract.term_end_date" class="w-64"/>
            </div>

            <div v-if="contractMode === 'edit'">
              <Button icon="pi pi-check"
                      class="border-none mt-2 ml-4"
                      label="Save Contract"
                      @click="saveContract"/>
            </div>
          </div>
          <div v-else>
            <Button
                class="bg-transparent border-none pl-0"
                icon="pi pi-plus"
                iconPos="left"
                label="Add new Contract"
                outlined
                @click="addContract" />
          </div>

        </Panel>

      </Drawer>
    </h1>

    <div class="card">
      <DataTable
          id="schoolsTable"
          v-model:filters="filters"
          :value="schools"
          removableSort
          rowHover
          stripedRows
          highlightOnSelect
          :rows="10"
          dataKey="id"
          filterDisplay="row"
          :loading="table_loading"
          :globalFilterFields="['name', 'firstAlias', 'firstReportingStream', 'aliasList', 'reportingStreamList', 'contractNumber', 'contractStartDate', 'contractEndDate']"
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 30]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} schools"
      >
        <template #header>
          <div class="flex justify-start w-[450px]">
            <IconField class="w-full">
              <InputIcon>
                <i class="pi pi-search"/>
              </InputIcon>
              <InputText
                  class="w-full"
                  v-model="filters['global'].value"
                  placeholder="Keyword Search"
              />
            </IconField>
          </div>
        </template>
        <template #empty> No schools found.</template>
        <template #loading> Loading schools data. Please wait.</template>

        <Column field="name" header="Name" :sortable="true" style="min-width: 12rem">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>

        <Column
            field="firstAlias"
            header="Aliases"
            :sortable="true"
            style="min-width: 12rem"
        >
          <template #body="{ data }">
            <Tag
                severity="secondary"
                :value="data.firstAlias"
                class="bg-gray-300 text-black mr-0.5"
            ></Tag>
            {{ data.aliasCount > 1 ? `+${data.aliasCount - 1}` : '' }}
          </template>
        </Column>


        <Column
            field="firstReportingStream"
            header="Reporting Streams"
            :sortable="true"
            style="min-width: 12rem"
        >
          <template #body="{ data }">
            <Tag
                severity="secondary"
                :value="data.firstReportingStream"
                class="bg-gray-300 text-black mr-0.5"
            ></Tag>
            {{ data.reportingStreamCount > 1 ? `+${data.reportingStreamCount - 1}` : '' }}
          </template>
        </Column>

        <Column
            field="contractNumber"
            header="Contracts"
            :sortable="true"
            style="min-width: 12rem"
        >
          <template #body="{ data }">
            {{ data?.contracts?.[0]?.contract_number || '' }}
          </template>
        </Column>

        <Column
            field="contractStartDate"
            header="Contract Start Date"
            :sortable="true"
            style="min-width: 12rem"
        >
          <template #body="{ data }">
            {{ data?.contractTermStartDate }}
          </template>
        </Column>

        <Column
            field="contractEndDate"
            header="Contract End Date"
            :sortable="true"
            style="min-width: 12rem">
          <template #body="{ data }">
            {{ data?.contractTermEndDate }}
          </template>
        </Column>

        <Column class="w-24 !text-end">
          <template #body="{ data }">
            <Button icon="pi pi-eye" @click="selectSchool(data)" severity="secondary" class="bg-transparent border-none"></Button>
          </template>
        </Column>
      </DataTable>

    </div>
  </div>
</template>

<style>
#aliasesTable .p-datatable-table-container .p-datatable-table .p-datatable-thead {
  display: none;
}

#aliasesTable .p-datatable-footer {
  background-color: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

#reportingStreamsTable .p-datatable-table-container .p-datatable-table .p-datatable-thead {
  display: none;
}

#reportingStreamsTable .p-datatable-footer {
  background-color: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
</style>