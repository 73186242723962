import axios from "axios";
import tokenService from "@/services/TokenService";

const AuthenticationService = {
    clearLoginData: (store) => {
        console.log('Clearing login data');
        console.log('Current user name:', store.state.userName);
        store.commit('removeUserName');
        store.commit('removeUserToken');
        store.commit('removeRefreshToken');
        store.commit('removeSession');
        console.log('finished clearing, Current user name:', store.state.userName);
    },
    callLogoutApi: async (store, router) => {
        await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/cognito/logout', {}, tokenService.getConfig(router, store))
            .then(() => {
                AuthenticationService.clearLoginData(store);
                router.push('/login');
            })
            .catch(error => {
                console.error('There was an error!', error);
                AuthenticationService.clearLoginData(store);
                router.push('/login');
            });
    },
    logoutByTimeout: (router, store, hideMessage) => {
        if(!store) {
            console.log('No store, skipping logout');
            router.push('/login');
            return;
        }

        AuthenticationService.clearLoginData(store);
        if (!hideMessage) {
            store.commit('setShowTokenExpiringMessage', 'Your session expired. Please login to use the system.');
        }

        router.push('/login');
    },
    disable: (store, router) => {
        store.commit('setUserToken', 'disabled');
        store.commit('setUserName', '');
        store.commit('setShowTokenExpiringMessage', '');
        router.push('/login');
    },
    checkIfResetPasswordRequired: async (email, password) => {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/login`, {
            email: email,
            password: password,
            authChallengeType: 'none'
        }).then((response) => {
            console.log('checkIfResetPasswordRequired response:', response);
            if (response.data.ChallengeName && response.data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
                console.error('ChallengeName detected:', response.data.ChallengeName);
                return {status: 'new_password_required'};
            }

            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to call login:', error.response || error);
            return {
                status: 'error',
                message: error.response.data.message
            };
        });
    },
    login: async (store, email, password, authChallengeType="email") => {
        console.log('Login from authentication service:', email, password);
        try {
            const response = await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/login`, {
                email: email,
                password: password,
                authChallengeType: authChallengeType
            });

            console.log('Login response:', response);

            if (response.data.ChallengeName && response.data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
                console.error('ChallengeName detected:', response.data.ChallengeName);
                return {status: 'new_password_required'};
            }

            console.log('Login success');
            store.commit('setSession', response.data.Session);

            return {status: 'success'};
        } catch (error) {
            console.error('Failed to login:', error.response || error);
            return {
                status: 'error',
                message: error.response.data.message
            };
        }
    },
    verifyMFACode: async (router, store, email, mfaCode, session) => {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/login/verify`, {
            email: email,
            code: mfaCode,
            session: session,
        }).then(async (response) => {
            console.log('MFA verified:', response.data);
            // Save the user token
            store.commit('setUserToken', response.data.access_token);
            store.commit('setRefreshToken', response.data.refresh_token);

            //set user name
            console.log('Getting and set user name');
            await AuthenticationService.getUserName(router, store);

            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to verify MFA:', error.response || error);
            const errorMessage = error.response?.data?.message;
            console.log('Error message:', errorMessage);
            //reset the session
            store.commit('removeSession');
            const newSession = error.response?.data?.Session ?? '';
            store.commit('setSession', newSession);
            return {status: 'error'};
        });
    },
    loginWithTempPassword: async function (router, store, email, tempPassword, newPassword, confirmPassword) {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/login-by-temporary-password`, {
            email: email,
            temporary_password: tempPassword,
            new_password: newPassword,
            new_password_confirmation: confirmPassword,
        }).then(async (response) => {
            store.commit('setUserToken', response.data.access_token);
            store.commit('setRefreshToken', response.data.refresh_token);

            //set user name
            await AuthenticationService.getUserName(router, store);
            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to login with temporary password:', error.response || error);
            return {status: 'error'};
        });
    },
    requestResetPasswordCode: async function (email) {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/forgot-password`, {
            email: email,
        }).then(() => {
            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to request reset password code:', error.response || error);
            return {status: 'error', message: error.response.data.message};
        });
    },
    forgetPasswordConfirm: async function (email, code, newPassword) {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/forgot-password/confirm`, {
            email: email,
            code: code,
            password: newPassword,
            password_confirmation: newPassword,
        }).then(() => {
            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to confirm forgot password:', error.response || error);
            return {status: 'error', message: error.response.data.message};
        });
    },
    changePassword: async function (router, store, current_password, newPassword) {
        return await axios.patch(process.env.VUE_APP_API_BASE_URL + `/api/cognito/passwords/change`, {
            current_password: current_password,
            new_password: newPassword,
            new_password_confirmation: newPassword,
        }, tokenService.getConfig(router, store))
            .then(() => {
                return {status: 'success'};
            }).catch((error) => {
                console.error('Failed to change password:', error.response || error);
                return {status: 'error', message: error.response.data.message};
            });
    },
    getUserName: async (router, store) => {
        await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/accounts/username', tokenService.getConfig(router, store))
            .then(response => {
                const userName = response.data.username;
                store.commit('setUserName', userName);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },
    resendConfirmationCode: async (email) => {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/signup/resend-confirmation-code`, {
            email: email,
        }).then(() => {
            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to resend confirmation code:', error.response || error);
            return {status: 'error', message: error.response.data.message};
        });
    },
    verifyConfirmationCode: async (email, code) => {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/signup/confirm`, {
            email: email,
            code: code,
        }).then(() => {
            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to verify confirmation code:', error.response || error);
            return {status: 'error', message: error.response.data.message};
        });
    }
}

export default AuthenticationService;
