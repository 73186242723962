<script setup>
import {ref, onMounted, inject, computed, watch, defineProps} from 'vue';
import AccountService from "@/services/AccountService";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import SchoolService from "@/services/SchoolService";
import {useConfirm} from "primevue/useconfirm";


const store = useStore();
const router = useRouter();
const accounts = ref();
const visible = ref(false);
const drawerTitle = ref('');
const editMode = ref(false);

const props = defineProps({
  showUsersTab: Boolean,
});

const showToastMessage = inject("showToastMessage");
const redirectUserToFirstAvailableTab = inject("redirectUserToFirstAvailableTab");


const filters = ref({
  global: { value: null },
  name: { value: null },
  email: { value: null },
  roles: { value: null },
  school_name: { value: null },
  status: { value: null },
  phone_number: { value: null },
  active: { value: null }
});


const loading = ref(true);



onMounted(async () => {
  if (!props.showUsersTab) {
    redirectUserToFirstAvailableTab();
    return;
  }

  const response = await AccountService.listAccounts(router, store);
  accounts.value = response.accounts;

  // loading is set to false after the accounts are fetched
  loading.value = false;

  const getSchoolNamesResponse = await SchoolService.getSchoolNames(router, store);
  if (getSchoolNamesResponse.status === 'success') {
    schools.value = getSchoolNamesResponse.schools.map(school => {
      return {name: school, value: school}
    });
  } else {
    console.error('Error fetching school names:', getSchoolNamesResponse.message);
  }
});



if (!showToastMessage) {
  console.error("showToastMessage is not provided");
}

async function enableUser() {
  const response = await AccountService.enableUser(selectedUser.value.email, router, store);
  if (response.status === 'success') {
    selectedUser.value.is_disabled = false;
    showToastMessage('success', 'Enabled', `${selectedUser.value.name} enabled`);
  } else {
    showToastMessage('error', 'Error', `Error enabling user: ${selectedUser.value.email}`);
    console.error('Error enabling user:', response.message);
  }
}

async function disableUser() {

  const response = await AccountService.disableUser(selectedUser.value.email, router, store);
  if (response.status === 'success') {
    selectedUser.value.is_disabled = true;
    showToastMessage('success', 'Disabled', `${selectedUser.value.name} disabled`);
  } else {
    showToastMessage('error', 'Error', `Error disabling user: ${selectedUser.value.email}`);
    console.error('Error disabling user:', response.message);
  }
}

async function resendTempPassword() {
  const response = await AccountService.resendTempPassword(selectedUser.value.email, router, store);
  if (response.status === 'success') {
    showToastMessage('success', 'Resent', `Temporary password for ${selectedUser.value.email} has been resent`);
  } else {
    showToastMessage('error', 'Error', `Error resending temp password: ${selectedUser.value.email}`);
    console.error('Error resending temp password:', response.message);
  }
}

const confirm = useConfirm();
const confirmEnableUser = () => {
  confirm.require({
    message: 'Are you sure you want to enable this user?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Save'
    },
    accept: async () => {
      await enableUser();
    },
    reject: () => {
      console.log('reject to enable user');
    }
  });
};

const confirmDisableUser = () => {
  confirm.require({
    message: 'Are you sure you want to disable this user?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Save'
    },
    accept: async () => {
      await disableUser();
    },
    reject: () => {
      console.log('reject to disable user');
    }
  });
};

const noteDialogVisible = ref(false);
const selectedNote = ref({
      id: '',
      content: ''
    });

const saveNote = async () => {
  const response = await AccountService.saveUserNote(
    selectedUser.value.id,
    selectedNote.value.id,
    selectedNote.value.content,
    router,
    store
  );

  if (response.status === 'success') {
    showToastMessage('success', 'Saved', 'Note saved successfully');
    //update the note_id in the selected user
    selectedUser.value.note_id = response.notes.id;
  } else {
    showToastMessage('error', 'Error', `Error saving note: ${response.message}`);
    console.error('Error saving note:', response.message);
  }

  noteDialogVisible.value = false;
};


const selectedUser = ref(null);
const actions = computed(() => [
  {
    label: 'Resend temp password',
    icon: 'pi pi-envelope',
    command: async () => {
      await resendTempPassword();
    },
    visible: selectedUser.value && selectedUser.value.status === 'FORCE_CHANGE_PASSWORD'
  },
  {
    label: 'Show Note',
    icon: 'pi pi-clipboard',
    command: async () => {
      if(selectedUser.value.note_id === null) {
        selectedNote.value = {
          id: '',
          content: ''
        };
      } else {
        const response = await AccountService.getUserNote(selectedUser.value.note_id, router, store);
        selectedNote.value = {
          id: selectedUser.value.note_id,
          content: response.notes.content
        };
      }

      noteDialogVisible.value = true;
    },
    visible: selectedUser.value && selectedUser.value.roles === 'School User'
  },
  {
    label: 'Enable User',
    icon: 'pi pi-user-plus',
    command: () => {
      confirmEnableUser();
    },
    visible: selectedUser.value && selectedUser.value.is_disabled
  },
  {
    label: 'Disable User',
    icon: 'pi pi-user-minus',
    command: () => {
      confirmDisableUser();
    },
    visible: selectedUser.value && !selectedUser.value.is_disabled
  },
  {
    label: 'Edit User',
    icon: 'pi pi-pencil',
    command: () => {
      toggleDrawer('Edit User', true);
      editMode.value = true;
      userBeforeEdit.value = {
        name: selectedUser.value.name,
        email: selectedUser.value.email,
        phone_number: selectedUser.value.phone_number,
        showSchoolSelection: selectedUser.value.roles === 'School User',
        school_name: schools.value.find(school => school.name === selectedUser.value.school_name),
        role: roles.value.find(role => role.name === selectedUser.value.roles),
      };

      newUser.value = {
        name: selectedUser.value.name,
        email: selectedUser.value.email,
        phone_number: selectedUser.value.phone_number,
        showSchoolSelection: selectedUser.value.roles === 'School User',
        school_name: schools.value.find(school => school.name === selectedUser.value.school_name),
        role: selectedUser.value.roles,
      };
    }
  }
]);


const menu = ref();
const toggle = (event, user) => {
  selectedUser.value = user;
  menu.value.toggle(event);
};

const roles = ref([
  { name: 'School User', value: 'school'},
  { name: 'Customer Care User', value: 'customer_care'},
  { name: 'Account Manager', value: 'account_manager'},
  { name: 'Customer Care Manager', value: 'customer_care_manager'},
  { name: 'Doc User', value: 'doc'},
  { name: 'API Consumer', value: 'api'},
]);

//keep track of new user old values
const userBeforeEdit = ref({
  name: '',
  email: '',
  role: null,
  phone_number: '',
  showSchoolSelection: false,
  school_name: '',
});

const newUser = ref({
  name: '',
  email: '',
  role: null,
  phone_number: '',
  showSchoolSelection: false,
  school_name: '',
});



const schools = ref();

const phone = ref(newUser.value.phone_number);
//watch for changes
watch(phone, (newVal, oldVal) => {
  console.log('phone number changed', newVal, oldVal);
  newUser.value.phone_number = newVal;
});

const telBindProps = {
  mode: 'international',
  defaultCountry: 'CA',
  disabledFetchingCountry: false,
  disabled: false,
  disabledFormatting: false,
  placeholder: 'Enter a phone number',
  required: false,
  enabledCountryCode: true,
  enabledFlags: true,
  preferredCountries: ['CA', 'US'],
  onlyCountries: [],
  ignoredCountries: [],
  autocomplete: 'true',
  name: 'telephone',
  maxLen: 25,
  validCharactersOnly: true,
  wrapperClasses: 'px-4',
  inputClasses: 'px-4',
  invalidMsg: 'Invalid phone number',
  dropdownOptions: {
    disabledDialCode: false,
    showFlags: true,
    showSearchBox: true,
    showDialCodeInList: true,
    showDialCodeInSelection: true
  },
  inputOptions: {
    showDialCode: true
  }
};

const errorMessages = ref({
  name: '',
  email: '',
  phone_number: '',
  role: '',
  school_name: '',
});

watch(() => newUser.value.name, (newVal) => {
  if (newVal) {
    errorMessages.value.name = '';
  }
});

watch(() => newUser.value.email, (newVal) => {
  if (newVal) {
    errorMessages.value.email = '';
  }
});

watch(() => newUser.value.phone_number, (newVal) => {
  if (newVal) {
    errorMessages.value.phone_number = '';
  }
});

watch(() => newUser.value.role, (newVal) => {
  if (newVal) {
    errorMessages.value.role = '';
  }
});

watch(() => newUser.value.school_name, (newVal) => {
  if (newVal) {
    errorMessages.value.school_name = '';
  }
});

//watch edit mode
watch(() => editMode.value, (newVal) => {
  if (!newVal) {
    newUser.value = {
      name: '',
      email: '',
      role: null,
      phone_number: '+1',
      showSchoolSelection: false,
      school_name: '',
    };
  }
});

const cancelNewUser = () => {
  newUser.value = {
    name: '',
    email: '',
    role: null,
    phone_number: '',
    showSchoolSelection: false,
    school_name: '',
  };

  //clear all error messages
  errorMessages.value = {
    name: '',
    email: '',
    phone_number: '',
    role: '',
    school_name: '',
  };

  visible.value = false;
  drawerTitle.value = '';
};



const updateUser = async () => {
  if (!newUser.value.name) {
    errorMessages.value.name = 'Name is required';
    return;
  }

  if (!newUser.value.phone_number) {
    errorMessages.value.phone_number = 'Phone number is required';
    return;
  }

  if (newUser.value.phone_number.length < 7) {
    errorMessages.value.phone_number = 'Phone number needs to be at least 7 digits';
    return;
  }


  console.log('new user role:', newUser.value.role);
  if (!newUser.value.role) {
    errorMessages.value.role = 'Role is required';
    return;
  }

  console.log('new user:', newUser.value);
  if (newUser.value.role.name === 'School User' && !newUser.value.school_name) {
    errorMessages.value.school_name = 'School name is required';
    return;
  }

  const changes = [];
  if (newUser.value.name !== userBeforeEdit.value.name || newUser.value.phone_number !== userBeforeEdit.value.phone_number) {
    console.log('name or phone number changed');
    changes.push('cognito');
  }


  console.log('new user role:', newUser.value.role);
  console.log('old user school_name:', userBeforeEdit.value.school_name);
  console.log('new user school_name:',newUser.value.school_name)
  if (newUser.value.school_name !== userBeforeEdit.value.school_name) {
    console.log('school name changed');
    changes.push('school_name');
  }

  const response = await AccountService.editUser(selectedUser.value.id, changes, newUser.value, router, store);

  if (response.status === 'success') {
    //update the user in the accounts list, only these fields: name, phone_number, roles, school_name
    const updatedUser = accounts.value.find(account => account.id === selectedUser.value.id);
    updatedUser.name = newUser.value.name;
    updatedUser.phone_number = newUser.value.phone_number;
    updatedUser.school_name = newUser.value.school_name?.name;
    showToastMessage('success', 'Updated', 'User updated successfully');

    //reset the form
    userBeforeEdit.value = {
      name: '',
      email: '',
      role: null,
      phone_number: '',
      showSchoolSelection: false,
      school_name: '',
    };

    cancelNewUser();
  } else {
    showToastMessage('error', 'Error', `Error updating user ${selectedUser.value.email}: ${response.message}`);
    console.error('Error updating user:', response.message);
  }
};

const saveNewUser = async () => {
  if (!newUser.value.name) {
    errorMessages.value.name = 'Name is required';
  }

  if (!newUser.value.email) {
    errorMessages.value.email = 'Email is required';
  }

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!emailPattern.test(newUser.value.email)) {
    errorMessages.value.email = 'Invalid email format';
  }

  if (!newUser.value.phone_number) {
    errorMessages.value.phone_number = 'Phone number is required';
  }

  if (newUser.value.phone_number.length < 7) {
    errorMessages.value.phone_number = 'Phone number needs to be at least 7 digits';
  }

  if (!newUser.value.role) {
    errorMessages.value.role = 'Role is required';
  }

  if (newUser.value.role && newUser.value.role.value === 'School User' && !newUser.value.school_name) {
    errorMessages.value.school_name = 'School name is required';
  }

  if (errorMessages.value.name || errorMessages.value.email || errorMessages.value.phone_number || errorMessages.value.role || errorMessages.value.school_name) {
    return;
  }

  const response = await AccountService.saveNewUser(newUser.value, router, store);
  if (response.status === 'success') {
    showToastMessage('success', 'Saved', 'User created successfully');
    const response = await AccountService.listAccounts(router, store);
    accounts.value = response.accounts;
    cancelNewUser();
  } else {
    showToastMessage('error', 'Error', `Error saving user ${newUser.value.email}: ${response.message}`);
    console.error('Error saving user:', response.message);
  }
};

const toggleDrawer = (title, edit) => {
  console.log('toggle drawer');
  visible.value = !visible.value;
  drawerTitle.value = title;
  editMode.value = edit;
};

</script>

<template>
  <div v-if="props.showUsersTab">
    <ConfirmDialog></ConfirmDialog>
    <Dialog v-model:visible="noteDialogVisible" modal header="Note" class="w-[600px] ">
      <div class="w-full card flex flex-col justify-center pr-2.5">
        <Textarea v-model="selectedNote.content" placeholder="Full Name, Phone Number, Additional Info." variant="filled" rows="5" cols="50" />

        <Button class="my-2 w-[150px]" @click="saveNote">
          <i class="pi pi-save mr-2"></i>
          <p>Save Note</p>
        </Button>
      </div>

    </Dialog>
    <h1 class="text-3xl font-bold py-8 text-primaryBlue flex items-center">
      Users Information
      <Button class="py-1 ml-8 flex items-center" @click="toggleDrawer('New User', false)">
        <i class="pi pi-plus mr-2"></i>
        <p>New User</p>
      </Button>
      <Drawer v-model:visible="visible"  position="right" class="w-[500px]" >
        <template #header>
          <h1 class="text-2xl justify-end"></h1>
        </template>
        <h1 class="text-2xl">{{drawerTitle}}</h1>

        <div>
          <p class="pt-8">Name</p>
          <InputText v-model="newUser.name"  class="w-full px-4"/>
          <Message severity="error" v-if="errorMessages.name"> {{errorMessages.name}} </Message>
        </div>


        <div>
          <p class="pt-8">Email</p>
          <InputText v-model="newUser.email" :disabled="editMode"  class="w-full px-4"/>
          <Message severity="error" v-if="errorMessages.email"> {{errorMessages.email}} </Message>
        </div>

        <div>
          <p class="pt-8">Mobile Phone</p>
          <vue-tel-input v-model="newUser.phone_number"
                         v-bind="telBindProps"/>
          <Message severity="error" v-if="errorMessages.phone_number"> {{errorMessages.phone_number}} </Message>
        </div>

        <div v-if="!editMode">
          <p class="pt-8">Role</p>
          <Select v-model="newUser.role" :options="roles" optionLabel="name"  class="w-full px-1" />
          <Message severity="error" v-if="errorMessages.role"> {{errorMessages.role}} </Message>
        </div>
        <div v-else>
          <p class="pt-8">Role Edit</p>
          <InputText v-model="selectedUser.roles"  class="w-full px-4" disabled/>
        </div>

        <div v-if="newUser.role && newUser.role==='School User'">
          <p class="pt-8">School</p>
          <Select v-model="newUser.school_name" :options="schools" optionLabel="name" placeholder="Select School" class="w-full px-1"/>
          <Message severity="error" v-if="errorMessages.school_name"> {{errorMessages.school_name}} </Message>
        </div>

        <div class="flex space-x-4 mt-6 mb-6">
          <Button @click="cancelNewUser" label="Cancel" outlined class="w-[120px]"/>
          <Button v-if="!editMode" @click="saveNewUser" label="Save" class="w-[120px]" icon="pi pi-check"/>
          <Button v-else @click="updateUser" label="Update" class="w-[120px]" icon="pi pi-check"/>
        </div>

      </Drawer>
    </h1>

    <div class="card w-full">
      <DataTable
          v-model:filters="filters"
          :value="accounts"
          removableSort
          rowHover
          stripedRows
          highlightOnSelect
          :rows="10"
          dataKey="id"
          filterDisplay="row"
          :loading="loading"
          :globalFilterFields="['name', 'email', 'phone_number', 'school_name', 'roles']"
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 30]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
      >
        <template #header>
          <div class="flex justify-start w-[450px]">
            <IconField class="w-full">
              <InputIcon>
                <i class="pi pi-search" />
              </InputIcon>
              <InputText class="w-full" v-model="filters['global'].value" placeholder="Keyword Search" />
            </IconField>
          </div>
        </template>
        <template #empty> No users found. </template>
        <template #loading> Loading users data. Please wait. </template>

        <Column field="name" header="Name" :sortable="true" class="max-w-[200px] whitespace-normal break-words">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>

        <Column field="email" header="Email" :sortable="true" class="max-w-[200px] whitespace-normal break-words">
          <template #body="{ data }">
            {{ data.email }}
          </template>
        </Column>

        <Column field="phone_number" header="Phone Number" :sortable="true" style="min-width: 12rem">
          <template #body="{ data }">
            {{ data.phone_number }}
          </template>
        </Column>


        <Column field="is_disabled" header="User Status" :sortable="true" style="min-width: 12rem">
          <template #body="{ data }">
            <Tag v-if="data.is_disabled" value="INACTIVE" severity="danger"/>
            <Tag v-else value="ACTIVE" severity="success"/>
          </template>
        </Column>

<!--        <Column field="status" header="Password status" :sortable="true" style="min-width: 12rem">-->
<!--          <template #body="{ data }">-->
<!--            {{ data.status }}-->
<!--          </template>-->
<!--        </Column>-->



        <Column field="roles" header="Roles" :sortable="true" style="min-width: 12rem">
          <template #body="{ data }">
            {{ data.roles }}
          </template>
        </Column>

        <Column field="school_name" header="School Name" :sortable="true" class="max-w-[200px] overflow-x-auto">
          <template #body="{ data }">
            {{ data.school_name }}
          </template>
        </Column>

        <Column field="action" header="Action" class="max-w-[200px] overflow-x-auto">
          <template #body="{ data }">
            <Button type="button" @click="(event) => toggle(event, data)" aria-haspopup="true" aria-controls="overlay_menu" outlined>
              Actions <i class="pi pi-chevron-down ml-2"></i>
            </Button>
            <Menu ref="menu" id="overlay_menu" :model="actions" :popup="true" class="text-primaryBlue">

            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<style>
.p-datatable-column-sorted {
  color: black!important;
}
.p-datatable-column-sorted .p-datatable-sort-icon  {
  color: black!important;
}

.p-menu-item-label {
  color: var(--p-datatable-row-color) !important;
}

.vti__dropdown-arrow {
  padding-left: 4px;
}


</style>