import tokenService from "@/services/TokenService";
import Vapor from 'laravel-vapor'
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import AuthenticationService from "@/services/AuthenticationService";

const FileService = {
    // eslint-disable-next-line no-unused-vars
    async uploadFile(router, store, file, contractId) {
        try {
            const token = store.state.userToken;
            // eslint-disable-next-line no-unused-vars
            const config = tokenService.getConfig(router, store);

            // Step 1: Get signed-storage-url
            const options = {
                baseURL: process.env.VUE_APP_API_BASE_URL,
                signedStorageUrl: '/vapor/signed-storage-url',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await Vapor.store(file, options);


            // Step 2: Upload file to S3
            const uploadResponse = await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/files/upload-s3', {
                name: file.name,
                uuid: response.uuid,
                bucket: response.bucket,
                key: response.key,
                url: response.url,
                contract_id: contractId,
            }, config);

            const cloudFileId = uploadResponse.data.id;

            // Step 3: Validate file
            const validateResponse = await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/files/validate', {
                fileId: cloudFileId,
            }, config);

            if (validateResponse.status === 200) {
                return {
                    status: 'success',
                    id: cloudFileId,
                };
            } else {
                return {
                    status: 'error',
                    message: validateResponse.data.message,
                };
            }


        } catch (error) {
            //handle disabled user error.
            console.log(error);
            if (error.response.status === 403 && error.response.data.message === 'User access is disabled by the admin') {
                return AuthenticationService.disable(store, router);
            }

            return {
                status: 'error',
                message: error.response?.data?.message || 'Unknown error',
            };
        }
    },
};

export default FileService;